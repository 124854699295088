@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Plus Jakarta Sans', sans-serif;
  scroll-behavior: smooth;
}
body,html{
  overflow-x: hidden
}



.bodyStiff {
	margin: 0; height: 100%; overflow: hidden;
}
.popupStiff{
  margin: 0; height: 100%; overflow-y: hidden;
}
.bodyStiff2 {
overflow-x: hidden
}


